<template>
    <div class="wrapper">

    </div>
</template>

<script>

    export default {
        name: "EmailNotifications",
        data() {
            return {

            }
        }
    }
</script>

<style lang="less">

</style>